
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
    AGTestSuiteResultFeedback,
    FeedbackCategory,
    ResultOutput,
    Submission
} from "ag-client-typescript";

import ViewFile from "@/components/view_file.vue";
import { handle_global_errors_async } from '@/error_handling';

@Component({
  components: {
    ViewFile
  }
})
export default class AGTestSuiteSetupResultDetail extends Vue {
  @Prop({required: true, type: Submission})
  submission!: Submission;

  @Prop({required: true, type: Object})
  ag_test_suite_result!: AGTestSuiteResultFeedback;

  @Prop({required: true, type: String})
  fdbk_category!: FeedbackCategory;

  d_setup_stdout_content: Promise<string> | null = null;
  d_setup_stderr_content: Promise<string> | null = null;
  d_setup_stdout_load_progress: number | null = null;
  d_setup_stderr_load_progress: number | null = null;
  d_output_size: ResultOutput.AGTestSuiteResultOutputSize | null = null;

  @Watch('fdbk_category')
  on_fdbk_category_change(new_value: FeedbackCategory, old_value: FeedbackCategory) {
    return this.get_output();
  }

  async created() {
    await this.get_output();
  }

  @handle_global_errors_async
  async get_output() {
    this.d_setup_stdout_content = null;
    this.d_setup_stderr_content = null;
    this.d_output_size = await ResultOutput.get_ag_test_suite_result_output_size(
      this.submission.pk,
      this.ag_test_suite_result.pk,
      this.fdbk_category
    );
    this.load_setup_stdout();
    this.load_setup_stderr();
  }

  load_setup_stdout() {
    if (this.d_output_size!.setup_stdout_size === null
        || this.d_output_size!.setup_stdout_size === 0) {
      return;
    }

    this.d_setup_stdout_load_progress = null;
    this.d_setup_stdout_content = ResultOutput.get_ag_test_suite_result_setup_stdout(
      this.submission.pk,
      this.ag_test_suite_result.pk,
      this.fdbk_category,
      (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.d_setup_stdout_load_progress = 100 * (1.0 * event.loaded / event.total);
        }
      }
    );
  }

  load_setup_stderr() {
    if (this.d_output_size!.setup_stderr_size === null
        || this.d_output_size!.setup_stderr_size === 0) {
      return;
    }

    this.d_setup_stderr_load_progress = null;
    this.d_setup_stderr_content = ResultOutput.get_ag_test_suite_result_setup_stderr(
      this.submission.pk,
      this.ag_test_suite_result.pk,
      this.fdbk_category,
      (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.d_setup_stderr_load_progress = 100 * (1.0 * event.loaded / event.total);
        }
      }
    );
  }
}
