
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
    BugsExposedFeedbackLevel,
    FeedbackCategory,
    MutationTestSuiteResultFeedback,
    ResultOutput,
    Submission
} from 'ag-client-typescript';

import { CorrectnessLevel } from '@/components/project_view/submission_detail/correctness';
import CorrectnessIcon from "@/components/project_view/submission_detail/correctness_icon.vue";
import ViewFile from "@/components/view_file.vue";
import { handle_global_errors_async } from '@/error_handling';

import MutantHints from "./mutant_hints/mutant_hints.vue";

@Component({
  components: {
    CorrectnessIcon,
    MutantHints,
    ViewFile
  }
})
export default class MutationSuiteResult extends Vue {
  @Prop({required: true, type: Object})
  mutation_test_suite_result!: MutationTestSuiteResultFeedback;

  @Prop({required: true, type: Submission})
  submission!: Submission;

  @Prop({required: true, type: String})
  fdbk_category!: FeedbackCategory;

  @Watch('mutation_test_suite_result')
  on_mutation_test_suite_results_change(new_value: MutationTestSuiteResultFeedback,
                                        old_value: MutationTestSuiteResultFeedback) {
    return this.get_output();
  }

  readonly CorrectnessLevel = CorrectnessLevel;
  readonly BugsExposedFeedbackLevel = BugsExposedFeedbackLevel;

  d_setup_stdout_content: Promise<string> | null = null;
  d_setup_stderr_content: Promise<string> | null = null;
  d_student_test_names_stdout_content: Promise<string> | null = null;
  d_student_test_names_stderr_content: Promise<string> | null = null;
  d_validity_check_stdout_content: Promise<string> | null = null;
  d_validity_check_stderr_content: Promise<string> | null = null;
  d_grade_buggy_stdout_content: Promise<string> | null = null;
  d_grade_buggy_stderr_content: Promise<string> | null = null;

  d_show_buggy_implementations_output = false;
  d_show_student_test_names_output = false;
  d_show_validity_check_output = false;

  d_load_grade_buggy_output = false;
  d_load_student_test_names_output = false;
  d_load_validity_check_output = false;

  d_setup_stdout_loaded = false;
  d_setup_stderr_loaded = false;
  d_student_test_names_stdout_loaded = false;
  d_student_test_names_stderr_loaded = false;
  d_validity_check_stdout_loaded = false;
  d_validity_check_stderr_loaded = false;
  d_grade_buggy_stdout_loaded = false;
  d_grade_buggy_stderr_loaded = false;
  d_output_size: ResultOutput.MutationTestSuiteResultOutputSize | null = null;

  async created() {
    await this.get_output();
  }

  get show_setup_fieldset(): boolean {
    return this.mutation_test_suite_result.fdbk_settings.show_setup_stdout
           || this.mutation_test_suite_result.fdbk_settings.show_setup_stderr
           || this.mutation_test_suite_result.setup_return_code !== null
           || (this.mutation_test_suite_result.setup_timed_out !== null
               && this.mutation_test_suite_result.setup_timed_out!);
  }

  get show_buggy_implementations_fieldset(): boolean {
    return this.mutation_test_suite_result.fdbk_settings.show_grade_buggy_impls_stdout
           || this.mutation_test_suite_result.fdbk_settings.show_grade_buggy_impls_stderr
           || this.mutation_test_suite_result.num_bugs_exposed !== null;
  }

  get show_exposed_bug_names() {
    return (this.mutation_test_suite_result.bugs_exposed !== null
            && this.mutation_test_suite_result.bugs_exposed.length !== 0)
           || this.mutation_test_suite_result.all_bug_names !== null;
  }

  // Selects between this.mutation_test_suite_result.bugs_exposed and
  // this.mutation_test_suite_result.all_bug_names.
  get bug_names_to_show() {
    return this.mutation_test_suite_result.all_bug_names
           ?? this.mutation_test_suite_result.bugs_exposed;
  }

  // Returns a Set with the contents of this.mutation_test_suite_result.bugs_exposed.
  // If this.mutation_test_suite_result.bugs_exposed is null, returns an empty set.
  get exposed_bug_set() {
    return new Set(this.mutation_test_suite_result.bugs_exposed);
  }

  toggle_d_show_buggy_implementations_output() {
    this.d_show_buggy_implementations_output = !this.d_show_buggy_implementations_output;
    if (this.d_show_buggy_implementations_output && !this.d_load_grade_buggy_output) {
      this.d_load_grade_buggy_output = true;
      this.d_grade_buggy_stdout_loaded = false;
      this.d_grade_buggy_stderr_loaded = false;
      this.load_grade_buggy_stdout_content();
      this.load_grade_buggy_stderr_content();
    }
  }

  toggle_d_show_student_test_names_output() {
    this.d_show_student_test_names_output = !this.d_show_student_test_names_output;
    if (this.d_show_student_test_names_output && !this.d_load_student_test_names_output) {
      this.d_load_student_test_names_output = true;
      this.d_student_test_names_stdout_loaded = false;
      this.d_student_test_names_stderr_loaded = false;
      this.load_student_test_names_stdout_content();
      this.load_student_test_names_stderr_content();
    }
  }

  toggle_d_show_validity_check_output() {
    this.d_show_validity_check_output = !this.d_show_validity_check_output;
    if (this.d_show_validity_check_output && !this.d_load_validity_check_output) {
      this.d_load_validity_check_output = true;
      this.d_validity_check_stdout_loaded = false;
      this.d_validity_check_stderr_loaded = false;
      this.load_validity_check_stdout_content();
      this.load_validity_check_stderr_content();
    }
  }

  @handle_global_errors_async
  async get_output() {
    this.d_output_size = await ResultOutput.get_mutation_test_suite_result_output_size(
      this.submission!.pk,
      this.mutation_test_suite_result.pk,
      this.fdbk_category
    );

    this.d_setup_stdout_loaded = false;
    this.d_setup_stderr_loaded = false;
    this.d_grade_buggy_stdout_loaded = false;
    this.d_grade_buggy_stderr_loaded = false;
    this.d_student_test_names_stdout_loaded = false;
    this.d_student_test_names_stderr_loaded = false;
    this.d_validity_check_stdout_loaded = false;
    this.d_validity_check_stderr_loaded = false;

    this.load_setup_stdout_content();
    this.load_setup_stderr_content();
    if (this.d_load_student_test_names_output) {
      this.load_student_test_names_stdout_content();
      this.load_student_test_names_stderr_content();
    }
    if (this.d_load_validity_check_output) {
      this.load_validity_check_stdout_content();
      this.load_validity_check_stderr_content();
    }
    if (this.d_load_grade_buggy_output) {
      this.load_grade_buggy_stdout_content();
      this.load_grade_buggy_stderr_content();
    }
  }

  load_setup_stdout_content() {
    if (this.d_output_size!.setup_stdout_size === null
        || this.d_output_size!.setup_stdout_size === 0) {
      this.d_setup_stdout_content = null;
    }
    else {
      this.d_setup_stdout_content = ResultOutput.get_mutation_test_suite_result_setup_stdout(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_setup_stdout_loaded = true;
  }

  load_setup_stderr_content() {
    if (this.d_output_size!.setup_stderr_size === null
        || this.d_output_size!.setup_stderr_size === 0) {
      this.d_setup_stderr_content = null;
    }
    else {
      this.d_setup_stderr_content = ResultOutput.get_mutation_test_suite_result_setup_stderr(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_setup_stderr_loaded = true;
  }

  load_student_test_names_stdout_content() {
    if (this.d_output_size!.get_student_test_names_stdout_size === null
        || this.d_output_size!.get_student_test_names_stdout_size === 0) {
      this.d_student_test_names_stdout_content = null;
    }
    else {
      this.d_student_test_names_stdout_content
          = ResultOutput.get_mutation_test_suite_result_get_student_test_names_stdout(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_student_test_names_stdout_loaded = true;
  }

  load_student_test_names_stderr_content() {
    if (this.d_output_size!.get_student_test_names_stderr_size === null
        || this.d_output_size!.get_student_test_names_stderr_size === 0) {
      this.d_student_test_names_stderr_content = null;
    }
    else {
      this.d_student_test_names_stderr_content
          = ResultOutput.get_mutation_test_suite_result_get_student_test_names_stderr(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_student_test_names_stderr_loaded = true;
  }

  load_validity_check_stdout_content() {
    if (this.d_output_size!.validity_check_stdout_size === null
        || this.d_output_size!.validity_check_stdout_size === 0) {
      this.d_validity_check_stdout_content = null;
    }
    else {
      this.d_validity_check_stdout_content
          = ResultOutput.get_mutation_test_suite_result_validity_check_stdout(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_validity_check_stdout_loaded = true;
  }

  load_validity_check_stderr_content() {
    if (this.d_output_size!.validity_check_stderr_size === null
        || this.d_output_size!.validity_check_stderr_size === 0) {
      this.d_validity_check_stderr_content = null;
    }
    else {
      this.d_validity_check_stderr_content
          = ResultOutput.get_mutation_test_suite_result_validity_check_stderr(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_validity_check_stderr_loaded = true;
  }

  load_grade_buggy_stdout_content() {
    if (this.d_output_size!.grade_buggy_impls_stdout_size === null
        || this.d_output_size!.grade_buggy_impls_stdout_size === 0) {
      this.d_grade_buggy_stdout_content = null;
    }
    else {
      this.d_grade_buggy_stdout_content
          = ResultOutput.get_mutation_test_suite_result_grade_buggy_impls_stdout(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_grade_buggy_stdout_loaded = true;
  }

  load_grade_buggy_stderr_content() {
    if (this.d_output_size!.grade_buggy_impls_stderr_size === null
        || this.d_output_size!.grade_buggy_impls_stderr_size === 0) {
      this.d_grade_buggy_stderr_content = null;
    }
    else {
      this.d_grade_buggy_stderr_content
          = ResultOutput.get_mutation_test_suite_result_grade_buggy_impls_stderr(
        this.submission.pk,
        this.mutation_test_suite_result.pk,
        this.fdbk_category
      );
    }
    this.d_grade_buggy_stderr_loaded = true;
  }

  test_timed_out(test: string): boolean {
    if (this.mutation_test_suite_result.timed_out_tests === null) {
        return false;
    }
    return this.mutation_test_suite_result.timed_out_tests!.findIndex(
        (item) => item === test
    ) !== -1;
  }

  get valid_tests(): string[] {
    if (this.mutation_test_suite_result.invalid_tests === null) {
        return this.mutation_test_suite_result.student_tests;
    }

    let invalid_tests = new Set(this.mutation_test_suite_result.invalid_tests);
    let valid_tests = this.mutation_test_suite_result.student_tests.filter(
      (student_test) => !invalid_tests.has(student_test)
    );
    return valid_tests;
  }
}
