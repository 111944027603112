
import { Component, Prop, Vue } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import {
    BugsExposedFeedbackLevel,
  ExpectedStudentFile,
  ExpectedStudentFileObserver,
  InstructorFile,
  InstructorFileObserver,
    MutationTestSuite,
    MutationTestSuiteObserver,
    Project,
    SandboxDockerImage,
} from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import LastSaved from "@/components/last_saved.vue";
import Modal from '@/components/modal.vue';
import {
    FeedbackConfigLabel,
    FeedbackDescriptions,
    MutationTestSuiteFeedbackPreset
} from '@/components/project_admin/feedback_config_panel/feedback_config_utils';
import BuggyImplementations from "@/components/project_admin/mutation_suites/buggy_implementations.vue";
import MutationCommand from "@/components/project_admin/mutation_suites/mutation_command.vue";
import MutationCommands from "@/components/project_admin/mutation_suites/mutation_commands.vue";
import MutationTestSuiteAdvancedFdbkSettings from '@/components/project_admin/mutation_suites/mutation_test_suite_advanced_fdbk_settings.vue';
import SuiteSettings from '@/components/project_admin/suite_settings.vue';
import Tooltip from "@/components/tooltip.vue";
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async, handle_global_errors_async, make_error_handler_func } from '@/error_handling';
import { SafeMap } from '@/safe_map';
import { deep_copy, format_datetime, toggle } from '@/utils';
import { is_not_empty } from '@/validators';

import FeedbackConfigPanel from '../feedback_config_panel/feedback_config_panel.vue';

@Component({
  components: {
    APIErrors,
    BuggyImplementations,
    Draggable,
    FeedbackConfigPanel,
    LastSaved,
    Modal,
    MutationCommand,
    MutationCommands,
    MutationTestSuiteAdvancedFdbkSettings,
    SuiteSettings,
    Tooltip,
    ValidatedForm,
    ValidatedInput
  }
})
export default class MutationSuites extends Vue implements MutationTestSuiteObserver,
                                                           InstructorFileObserver,
                                                           ExpectedStudentFileObserver {
  @Prop({required: true, type: Project})
  project!: Project;

  readonly FeedbackConfigLabel = FeedbackConfigLabel;
  readonly FeedbackDescriptions = FeedbackDescriptions;
  readonly is_not_empty = is_not_empty;
  readonly format_datetime = format_datetime;

  d_docker_images: SandboxDockerImage[] = [];

  d_active_mutation_test_suite: MutationTestSuite | null = null;
  d_add_mutation_test_suite_form_is_valid = true;
  d_adding_suite = false;
  d_loading = true;
  d_mutation_test_suites: MutationTestSuite[] = [];
  d_new_mutation_test_suite_name = "";
  d_deleting = false;
  d_saving = false;
  d_num_save_api_errors = 0;
  d_settings_form_is_valid = true;
  d_show_new_mutation_test_suite_modal = false;
  d_show_delete_mutation_test_suite_modal = false;

  d_collapsed = false;

  @handle_global_errors_async
  async created() {
    MutationTestSuite.subscribe(this);
    InstructorFile.subscribe(this);
    ExpectedStudentFile.subscribe(this);
    this.d_mutation_test_suites = await MutationTestSuite.get_all_from_project(this.project.pk);
    let global_images = await SandboxDockerImage.get_images(null);
    let course_images = await SandboxDockerImage.get_images(this.project.course);
    this.d_docker_images = global_images.concat(course_images);
    this.d_loading = false;
  }

  set_active_mutation_test_suite(active_mutation_test_suite: MutationTestSuite) {
    // FIXME: Add tests for suite deep copy behavior
    this.d_active_mutation_test_suite = deep_copy(active_mutation_test_suite, MutationTestSuite);
  }

  beforeDestroy() {
    MutationTestSuite.unsubscribe(this);
    InstructorFile.unsubscribe(this);
    ExpectedStudentFile.unsubscribe(this);
  }

  @handle_api_errors_async(handle_add_mutation_test_suite_error)
  async add_mutation_test_suite() {
    try {
      this.d_adding_suite = true;
      await MutationTestSuite.create(
        this.project.pk, {name: this.d_new_mutation_test_suite_name}
      );
      this.d_show_new_mutation_test_suite_modal = false;
      this.d_new_mutation_test_suite_name = "";
    }
    finally {
      this.d_adding_suite = false;
    }
  }

  @handle_api_errors_async(make_error_handler_func('delete_errors'))
  async delete_mutation_test_suite() {
    return toggle(this, 'd_deleting', async () => {
      await this.d_active_mutation_test_suite!.delete();
      this.d_show_delete_mutation_test_suite_modal = false;
      this.d_active_mutation_test_suite = null;
    });
  }

  @handle_global_errors_async
  set_mutation_test_suite_order() {
    return MutationTestSuite.update_order(
      this.project.pk, this.d_mutation_test_suites.map(suite => suite.pk));
  }

  open_new_mutation_test_suite_modal() {
    this.d_new_mutation_test_suite_name = "";
    this.d_show_new_mutation_test_suite_modal = true;
    Vue.nextTick(() => {
      (<ValidatedInput> this.$refs.new_mutation_test_suite_name).focus();
    });
  }

  update_instructor_file_created(instructor_file: InstructorFile): void {}
  update_instructor_file_renamed(instructor_file: InstructorFile, old_name: string): void { }
  update_instructor_file_content_changed(
    instructor_file: InstructorFile, new_content: Blob): void {}
  update_instructor_file_deleted(instructor_file: InstructorFile): void {
    for (let suite of this.d_mutation_test_suites) {
      suite.instructor_files_needed.splice(
        suite.instructor_files_needed.findIndex(file => file.pk === instructor_file.pk),
        1
      );
    }
  }
  update_expected_student_file_created(expected_student_file: ExpectedStudentFile): void {}
  update_expected_student_file_changed(expected_student_file: ExpectedStudentFile): void {}
  update_expected_student_file_deleted(expected_student_file: ExpectedStudentFile): void {
    for (let suite of this.d_mutation_test_suites) {
      suite.student_files_needed.splice(
        suite.student_files_needed.findIndex(file => file.pk === expected_student_file.pk),
        1
      );
    }
  }

  update_mutation_test_suite_created(mutation_test_suite: MutationTestSuite): void {
    if (mutation_test_suite.project === this.project.pk) {
      this.d_mutation_test_suites.push(mutation_test_suite);
      this.set_active_mutation_test_suite(mutation_test_suite);
    }
  }

  update_mutation_test_suite_changed(mutation_test_suite: MutationTestSuite): void {
    if (mutation_test_suite.project !== this.project.pk) {
      return;
    }

    let index = this.d_mutation_test_suites.findIndex(
      (mutation_suite: MutationTestSuite) => mutation_suite.pk === mutation_test_suite.pk
    );
    let changed_suite_is_active = this.d_mutation_test_suites[index]
                                  === this.d_active_mutation_test_suite;
    Vue.set(this.d_mutation_test_suites,
            index,
            deep_copy(mutation_test_suite, MutationTestSuite)
    );
    if (changed_suite_is_active) {
      this.set_active_mutation_test_suite(this.d_mutation_test_suites[index]);
    }
  }

  update_mutation_test_suite_deleted(mutation_test_suite: MutationTestSuite): void {
    if (mutation_test_suite.project !== this.project.pk) {
      return;
    }

    let index = this.d_mutation_test_suites.findIndex(
      (mutation_suite: MutationTestSuite) => mutation_suite.pk === mutation_test_suite.pk
    );
    this.d_mutation_test_suites.splice(index, 1);
  }

  update_mutation_test_suites_order_changed(project_pk: number,
                                            mutation_test_suite_order: number[]): void {
  }

  readonly fdbk_presets = new SafeMap<string, MutationTestSuiteFeedbackPreset>([
    [
      'Everything',
      {
        show_setup_return_code: true,
        show_setup_stdout: true,
        show_setup_stderr: true,
        show_get_test_names_return_code: true,
        show_get_test_names_stdout: true,
        show_get_test_names_stderr: true,
        show_validity_check_stdout: true,
        show_validity_check_stderr: true,
        show_grade_buggy_impls_stdout: true,
        show_grade_buggy_impls_stderr: true,
        show_invalid_test_names: true,
        show_points: true,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.all_bug_names
      }
    ],
    [
      'Bugs Exposed + All Output',
      {
        show_setup_return_code: true,
        show_setup_stdout: true,
        show_setup_stderr: true,
        show_get_test_names_return_code: true,
        show_get_test_names_stdout: true,
        show_get_test_names_stderr: true,
        show_validity_check_stdout: true,
        show_validity_check_stderr: true,
        show_grade_buggy_impls_stdout: true,
        show_grade_buggy_impls_stderr: true,
        show_invalid_test_names: true,
        show_points: true,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.exposed_bug_names
      }
    ],
    [
      'Num Bugs + Prep Output',
      {
        show_setup_return_code: true,
        show_setup_stdout: true,
        show_setup_stderr: true,
        show_get_test_names_return_code: true,
        show_get_test_names_stdout: true,
        show_get_test_names_stderr: true,
        show_validity_check_stdout: true,
        show_validity_check_stderr: true,
        show_grade_buggy_impls_stdout: false,
        show_grade_buggy_impls_stderr: false,
        show_invalid_test_names: true,
        show_points: true,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.num_bugs_exposed
      }
    ],
    [
      'Num Bugs Exposed',
      {
        show_setup_return_code: true,
        show_setup_stdout: false,
        show_setup_stderr: false,
        show_get_test_names_return_code: false,
        show_get_test_names_stdout: false,
        show_get_test_names_stderr: false,
        show_validity_check_stdout: false,
        show_validity_check_stderr: false,
        show_grade_buggy_impls_stdout: false,
        show_grade_buggy_impls_stderr: false,
        show_invalid_test_names: true,
        show_points: true,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.num_bugs_exposed
      }
    ],
    [
      'False Positives',
      {
        show_setup_return_code: true,
        show_setup_stdout: false,
        show_setup_stderr: false,
        show_get_test_names_return_code: false,
        show_get_test_names_stdout: false,
        show_get_test_names_stderr: false,
        show_validity_check_stdout: false,
        show_validity_check_stderr: false,
        show_grade_buggy_impls_stdout: false,
        show_grade_buggy_impls_stderr: false,
        show_invalid_test_names: true,
        show_points: false,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.no_feedback
      }
    ],

    [
      'Private',
      {
        show_setup_return_code: false,
        show_setup_stdout: false,
        show_setup_stderr: false,
        show_get_test_names_return_code: false,
        show_get_test_names_stdout: false,
        show_get_test_names_stderr: false,
        show_validity_check_stdout: false,
        show_validity_check_stderr: false,
        show_grade_buggy_impls_stdout: false,
        show_grade_buggy_impls_stderr: false,
        show_invalid_test_names: false,
        show_points: false,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.no_feedback
      }
    ],

    [
      'Num Bugs Exposed (Old)',
      {
        show_setup_return_code: true,
        show_setup_stdout: false,
        show_setup_stderr: false,
        show_get_test_names_return_code: true,
        show_get_test_names_stdout: false,
        show_get_test_names_stderr: false,
        show_validity_check_stdout: false,
        show_validity_check_stderr: false,
        show_grade_buggy_impls_stdout: false,
        show_grade_buggy_impls_stderr: false,
        show_invalid_test_names: true,
        show_points: true,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.num_bugs_exposed
      }
    ],
    [
      'False Positives (old)',
      {
        show_setup_return_code: true,
        show_setup_stdout: false,
        show_setup_stderr: false,
        show_get_test_names_return_code: true,
        show_get_test_names_stdout: false,
        show_get_test_names_stderr: false,
        show_validity_check_stdout: false,
        show_validity_check_stderr: false,
        show_grade_buggy_impls_stdout: false,
        show_grade_buggy_impls_stderr: false,
        show_invalid_test_names: true,
        show_points: true,
        bugs_exposed_fdbk_level: BugsExposedFeedbackLevel.no_feedback
      }
    ],
  ]);

  @handle_api_errors_async(handle_save_mutation_test_suite_error)
  async save_mutation_test_suite() {
    try {
      this.d_saving = true;
      (<APIErrors> this.$refs.save_errors).clear();
      await this.d_active_mutation_test_suite!.save();
    }
    finally {
      this.d_saving = false;
    }
  }
}

function handle_save_mutation_test_suite_error(component: MutationSuites, error: unknown) {
  let api_errors_elt = <APIErrors> component.$refs.save_errors;
  api_errors_elt.show_errors_from_response(error);
  if (component.d_num_save_api_errors !== 0) {
    api_errors_elt.$el.scrollIntoView({behavior: 'smooth'});
  }
}

function handle_add_mutation_test_suite_error(component: MutationSuites, error: unknown) {
    (<APIErrors> component.$refs.create_errors).show_errors_from_response(error);
}
