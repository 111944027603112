
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import { BuildImageStatus, BuildSandboxDockerImageTask } from 'ag-client-typescript';
import FileSaver from 'file-saver';

import { GlobalData } from '@/app.vue';
import ProgressOverlay from '@/components/progress_overlay.vue';
import ViewFile from '@/components/view_file.vue';
import { SYSADMIN_CONTACT } from '@/constants';
import { handle_global_errors_async } from '@/error_handling';
import { blob_to_string, format_datetime, toggle } from '@/utils';

import BuildImageStatusIcon from './build_image_status_icon.vue';

@Component({
  components: {
    BuildImageStatusIcon,
    ProgressOverlay,
    ViewFile,
  }
})
export default class BuildImageTaskDetail extends Vue {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({required: true, type: BuildSandboxDockerImageTask})
  build_task!: BuildSandboxDockerImageTask;

  @Prop({required: true, type: Boolean})
  refresh_in_progress!: boolean;

  readonly format_datetime = format_datetime;
  readonly SYSADMIN_CONTACT = SYSADMIN_CONTACT;
  readonly BuildImageStatus = BuildImageStatus;

  private d_output_download_progress: number | null = null;
  private d_output: Promise<string> | null = null;

  private d_files_download_progress: number | null = null;
  d_downloading_files = false;

  d_cancelling_build = false;

  tips_and_tricks_url = 'https://eecs-autograder.github.io/autograder.io'
                        + '/topics/custom_sandbox_images.html#tips-and-tricks';

  created() {
    return this.load_output();
  }

  @Watch('build_task')
  on_build_task_change(
      new_task: BuildSandboxDockerImageTask, old_task: BuildSandboxDockerImageTask) {
    if (new_task.status !== old_task.status || new_task.status === BuildImageStatus.in_progress) {
      return this.load_output();
    }
  }

  refresh_images_and_build_tasks() {
    if (!this.refresh_in_progress) {
      this.$emit('refresh_images_and_build_tasks');
    }
  }

  @handle_global_errors_async
  private download_files() {
    this.d_files_download_progress = null;
    return toggle(this, 'd_downloading_files', async () => {
      let content = this.build_task.get_files(event => {
          if (event.lengthComputable) {
              this.d_files_download_progress = 100 * (1.0 * event.loaded / event.total);
          }
      });
      FileSaver.saveAs(new File([await content], `build${this.build_task.pk}_files.zip`));
    });
  }

  @handle_global_errors_async
  load_output() {
    if (this.build_task.status === BuildImageStatus.queued) {
      // Output file might not exist yet.
      return;
    }

    this.d_output_download_progress = null;
    let output = this.build_task.get_output((event) => {
      if (event.lengthComputable) {
        this.d_output_download_progress = 100 * (1.0 * event.loaded / event.total);
      }
    });
    this.d_output = blob_to_string(output);
  }

  @handle_global_errors_async
  cancel_build() {
    return toggle(this, 'd_cancelling_build', () => {
      return this.build_task.cancel();
    });
  }
}
