

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
    AGTestCommandResultFeedback,
    ExpectedReturnCode,
    FeedbackCategory,
    ResultOutput,
    Submission,
    ValueFeedbackLevel
} from "ag-client-typescript";

import Diff from '@/components/diff.vue';
import ViewFile from "@/components/view_file.vue";
import { handle_global_errors_async } from '@/error_handling';

@Component({
  components: {
    Diff,
    ViewFile
  }
})
export default class AGTestCommandResultDetail extends Vue {
  @Prop({required: true, type: Submission})
  submission!: Submission;

  @Prop({required: true, type: Object})
  ag_test_command_result!: AGTestCommandResultFeedback;

  @Prop({required: true, type: String})
  fdbk_category!: FeedbackCategory;

  d_output_size: ResultOutput.AGTestCommandResultOutputSize | null = null;

  d_stdout_content: Promise<string> | null = null;
  d_stderr_content: Promise<string> | null = null;
  d_stdout_load_progress: number | null = null;
  d_stderr_load_progress: number | null = null;

  d_stdout_diff: Promise<string[]> | null = null;
  d_stderr_diff: Promise<string[]> | null = null;
  d_stdout_diff_load_progress: number | null = null;
  d_stderr_diff_load_progress: number | null = null;

  @Watch('fdbk_category')
  on_fdbk_category_change(new_value: FeedbackCategory, old_value: FeedbackCategory) {
    return this.get_output();
  }

  readonly ExpectedReturnCode = ExpectedReturnCode;
  readonly ValueFeedbackLevel = ValueFeedbackLevel;

  created() {
    return this.get_output();
  }

  get show_correctness_fieldset() {
    return this.ag_test_command_result.return_code_correct !== null
           || (this.ag_test_command_result.timed_out !== null
               && this.ag_test_command_result.timed_out!)
           || this.ag_test_command_result.actual_return_code !== null
           || this.ag_test_command_result.stdout_correct !== null
           || this.ag_test_command_result.stderr_correct !== null;
  }

  @handle_global_errors_async
  async get_output() {
    this.d_stdout_content = null;
    this.d_stderr_content = null;
    this.d_stdout_diff = null;
    this.d_stderr_diff = null;

    this.d_output_size = await ResultOutput.get_ag_test_cmd_result_output_size(
      this.submission.pk,
      this.ag_test_command_result.pk,
      this.fdbk_category
    );
    this.load_stdout_diff();
    this.load_stderr_diff();
    this.load_stdout_content();
    this.load_stderr_content();
  }

  load_stdout_content() {
    if (this.d_output_size!.stdout_size === null || this.d_output_size!.stdout_size === 0) {
      return;
    }

    this.d_stdout_load_progress = null;
    this.d_stdout_content = ResultOutput.get_ag_test_cmd_result_stdout(
      this.submission.pk,
      this.ag_test_command_result.pk,
      this.fdbk_category,
      (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.d_stdout_load_progress = 100 * (1.0 * event.loaded / event.total);
        }
      }
    );
  }

  load_stderr_content() {
    if (this.d_output_size!.stderr_size === null || this.d_output_size!.stderr_size === 0) {
      return;
    }

    this.d_stderr_load_progress = null;
    this.d_stderr_content = ResultOutput.get_ag_test_cmd_result_stderr(
      this.submission.pk,
      this.ag_test_command_result.pk,
      this.fdbk_category,
      (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.d_stderr_load_progress = 100 * (1.0 * event.loaded / event.total);
        }
      }
    );
  }

  load_stdout_diff() {
    if (this.d_output_size!.stdout_diff_size === null
        || this.d_output_size!.stdout_diff_size === 0) {
      return;
    }

    this.d_stdout_diff_load_progress = null;
    this.d_stdout_diff = ResultOutput.get_ag_test_cmd_result_stdout_diff(
      this.submission.pk,
      this.ag_test_command_result.pk,
      this.fdbk_category,
      (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.d_stdout_diff_load_progress = 100 * (1.0 * event.loaded / event.total);
        }
      }
    );
  }

  load_stderr_diff() {
    if (this.d_output_size!.stderr_diff_size === null
        || this.d_output_size!.stderr_diff_size === 0) {
      return;
    }

    this.d_stderr_diff_load_progress = null;
    this.d_stderr_diff = ResultOutput.get_ag_test_cmd_result_stderr_diff(
      this.submission.pk,
      this.ag_test_command_result.pk,
      this.fdbk_category,
      (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.d_stderr_diff_load_progress = 100 * (1.0 * event.loaded / event.total);
        }
      }
    );
  }
}
